export default {
  "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["R.S"])},
  "website_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arab Identity"])},
  "our_products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["our products"])},
  "more_about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["more about us"])},
  "new_arrivals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["new arrivals"])},
  "add_to_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["add to cart"])},
  "about_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["about us"])},
  "follow_on_socials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["follow on socials"])},
  "useful_links": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["useful links"])},
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["home"])},
  "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["about"])},
  "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contact us"])},
  "my_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["my account"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["login"])},
  "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["register"])},
  "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["profile"])},
  "wishlist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wishlist"])},
  "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contact"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["phone"])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["message"])},
  "fax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fax"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["address"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["email"])},
  "marwan_tech": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["marwan tech"])},
  "login_or_register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["login or register"])},
  "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["account"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["logout"])},
  "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["success"])},
  "you_have_registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["you have been registered successfully"])},
  "you_have_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["you have been login successfully"])},
  "wrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wrong"])},
  "unauthorised_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["there is error in email or password, you can't login"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["name"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["password"])},
  "register_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["register now"])},
  "error404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["error 404"])},
  "page_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["page not found"])},
  "featured_products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["featured products"])},
  "message_sent_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mesage sent successfully"])},
  "wishlist_updated_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wishlist updated successfully"])},
  "wishlist_not_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["an error occurred while updating the favorites list"])},
  "cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cart"])},
  "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["orders"])},
  "learn_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["more about us"])},
  "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["all"])},
  "you_already_reviewed_this_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["you already reviewed this product"])},
  "your_review_sent_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["your review sent successfully"])},
  "products_added_to_your_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["products added to cart"])},
  "view_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["view cart"])},
  "in_stock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["in stock"])},
  "out_of_stock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["out of stock"])},
  "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["review"])},
  "review_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["review message"])},
  "send_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["send review"])},
  "sending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sending"])},
  "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["send"])},
  "share_this_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["share this product"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["description"])},
  "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reviews"])},
  "add_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["add review"])},
  "your_email_address_will_not_be_published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["your email address will not be published"])},
  "rating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rating"])},
  "login_to_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["login to review"])},
  "all_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["all reviews"])},
  "no_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no reviews"])},
  "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["results"])},
  "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["categories"])},
  "oops": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oops"])},
  "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no results"])},
  "recapcha_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["select recapcha first"])},
  "total_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["total price"])},
  "proceed_to_checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["proceed to checkout"])},
  "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["product"])},
  "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["price"])},
  "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["quantity"])},
  "subtotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["subtotal"])},
  "checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["checkout"])},
  "checkout_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["checkout details"])},
  "your_order_sent_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["your order sent successfully"])},
  "order_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["order now"])},
  "no_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no orders"])},
  "order_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["order number"])},
  "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["total"])},
  "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["status"])},
  "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["products"])},
  "your_data_updated_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["your data updated successfully"])},
  "edit_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["edit profile"])},
  "change_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["change password"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["date"])},
  "billing_way": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["billing way"])},
  "on_delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["on delivery"])},
  "no_data_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no data found"])},
  "adding_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["adding date"])},
  "controle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["controle"])},
  "thanks_your_request_has_been_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thanks, your request has been received."])},
  "confirm_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["confirm password"])},
  "new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["new password"])},
  "old_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["old password"])},
  "save_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["save changes"])},
  "thanks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["thanks"])},
  "no_products_in_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no products in cart"])},
  "AboutView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["about us"])},
  "ProductsView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["products"])},
  "SingleProductView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["single product"])},
  "AccountView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["account"])},
  "ProfileView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["profile"])},
  "WishlistView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wishlist"])},
  "CartView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cart"])},
  "CheckoutView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["checkout"])},
  "ThanksView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["thanks"])},
  "OrdersView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["orders"])},
  "ContactView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contact"])},
  "Error404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["error 404"])},
  "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pending"])},
  "shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["shipping"])},
  "delivered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["delivered"])},
  "canceled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["canceled"])},
  "checkProductsClick": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check our products"])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
}