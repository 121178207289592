export default {
  "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ر.س"])},
  "website_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هوية العرب"])},
  "our_products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منتجاتنا"])},
  "more_about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المزيد عنا"])},
  "new_arrivals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أحدث المنتجات"])},
  "add_to_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أضف إلى السلة"])},
  "about_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من نحن"])},
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرئيسية"])},
  "follow_on_socials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مواقع التواصل"])},
  "useful_links": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["روابط مفيدة"])},
  "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من نحن"])},
  "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تواصل معنا"])},
  "my_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحساب الشخصي"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل دخول"])},
  "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء حساب"])},
  "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الملف الشخصي"])},
  "wishlist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قائمة المفضلة"])},
  "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تواصل معنا"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الهاتف"])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرسالة"])},
  "fax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فاكس"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العنوان"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البريد الإلكتروني"])},
  "marwan_tech": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مروان تك"])},
  "login_or_register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل دخول أو إنشاء حساب"])},
  "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحساب الشخصي"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل خروج"])},
  "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مرحباً"])},
  "you_have_registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم إنشاء الحساب الشخصي بنجاح"])},
  "you_have_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم تسجيل الدخول بنجاح"])},
  "wrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطأ"])},
  "unauthorised_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البريد الإلكتروني أو كلمة المرور غير صحيح"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور"])},
  "register_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء حساب الآن"])},
  "error404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطأ 404"])},
  "page_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم يتم إيجاد الصفحة"])},
  "featured_products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منتجات مميزة"])},
  "message_sent_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم استلام رسالتك بنجاح وسيتم التواصل معك في أقرب وقت، شكرا لك"])},
  "wishlist_updated_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم تحديث القائمة المفضلة بنجاح"])},
  "wishlist_not_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حدث خطأ أثناء تحديث القائمة المفضلة"])},
  "cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سلة الشراء"])},
  "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جميع الطلبات"])},
  "learn_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معرفة المزيد عنا"])},
  "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجميع"])},
  "you_already_reviewed_this_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لقد قمت بتقييم هذا النتج من قبل"])},
  "your_review_sent_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم إرسال تقييمك بنجاح"])},
  "products_added_to_your_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منتج تم إضافته للسلة"])},
  "view_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض السلة"])},
  "in_stock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المنتج موجود"])},
  "out_of_stock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المنتج غير موجود"])},
  "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقييم"])},
  "review_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رسالة التقييم"])},
  "send_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إرسال تقييم"])},
  "sending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جاري الإرسال"])},
  "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إرسال"])},
  "share_this_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مشاركة هذا المنتج"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوصف"])},
  "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التقييمات"])},
  "add_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة مراجعة"])},
  "your_email_address_will_not_be_published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لن يتم نشر بريدك الإلكتروني"])},
  "rating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التقييم"])},
  "login_to_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["للتقييم، سجل دخول"])},
  "all_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جميع التقييمات"])},
  "no_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد تقييمات أو مراجعات"])},
  "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من النتائج"])},
  "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأصناف"])},
  "oops": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فارغ"])},
  "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد نتائج"])},
  "recapcha_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قم بتحديد إنك لست روبوتا"])},
  "total_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المجموع الكلي"])},
  "proceed_to_checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التقدم للدفع"])},
  "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المنتج"])},
  "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السعر"])},
  "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكمية"])},
  "subtotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإجمالي"])},
  "checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدفع"])},
  "checkout_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفاصيل الدفع"])},
  "your_order_sent_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم إرسال طلبك بنجاح"])},
  "order_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اطلب الآن"])},
  "no_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد طلبات"])},
  "order_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الطلب"])},
  "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المجموع"])},
  "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحالة"])},
  "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المنتجات"])},
  "your_data_updated_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم تحديث بياناتك بنجاح"])},
  "edit_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل الملف الشخصي"])},
  "change_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تغيير كلمة المرور"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التاريخ"])},
  "billing_way": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طريقة الدفع"])},
  "on_delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عند التسليم"])},
  "no_data_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد بيانات"])},
  "adding_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الإضافة"])},
  "controle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التحكم"])},
  "thanks_your_request_has_been_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شكرا، تم استلام الطلب بنجاح"])},
  "confirm_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكيد كلمة المرور"])},
  "new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور الجديدة"])},
  "old_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور القديمة"])},
  "save_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ المتغيرات"])},
  "thanks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ملخص الطلب"])},
  "no_products_in_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم يتم إضافة منتجات للسلة"])},
  "AboutView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من نحن"])},
  "ProductsView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المنتجات"])},
  "SingleProductView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صفحة المنتج"])},
  "AccountView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحساب الشخصي"])},
  "ProfileView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الملف الشخصي"])},
  "WishlistView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قائمة المفضلة"])},
  "CartView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سلة الشراء"])},
  "CheckoutView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدفع"])},
  "ThanksView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ملخص الطلب"])},
  "OrdersView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جميع الطلبات"])},
  "ContactView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تواصل معنا"])},
  "Error404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صفحة غير موجودة"])},
  "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["في الانتظار"])},
  "shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم الشحن"])},
  "delivered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم التوصيل"])},
  "canceled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم الإلغاء"])},
  "checkProductsClick": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تصفح منتجاتنا"])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
}